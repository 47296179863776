// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-b-306-js": () => import("./../../../src/pages/b306.js" /* webpackChunkName: "component---src-pages-b-306-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-family-planner-js": () => import("./../../../src/pages/family/planner.js" /* webpackChunkName: "component---src-pages-family-planner-js" */),
  "component---src-pages-family-recipes-js": () => import("./../../../src/pages/family/recipes.js" /* webpackChunkName: "component---src-pages-family-recipes-js" */),
  "component---src-pages-homepage-art-js": () => import("./../../../src/pages/homepage/art.js" /* webpackChunkName: "component---src-pages-homepage-art-js" */),
  "component---src-pages-homepage-blog-js": () => import("./../../../src/pages/homepage/blog.js" /* webpackChunkName: "component---src-pages-homepage-blog-js" */),
  "component---src-pages-homepage-games-js": () => import("./../../../src/pages/homepage/games.js" /* webpackChunkName: "component---src-pages-homepage-games-js" */),
  "component---src-pages-homepage-projects-js": () => import("./../../../src/pages/homepage/projects.js" /* webpackChunkName: "component---src-pages-homepage-projects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-runescape-js": () => import("./../../../src/pages/runescape.js" /* webpackChunkName: "component---src-pages-runescape-js" */),
  "component---src-templates-contentful-blog-post-js": () => import("./../../../src/templates/contentful-blog-post.js" /* webpackChunkName: "component---src-templates-contentful-blog-post-js" */)
}

