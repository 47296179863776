const colorScales = {
  greyScale: [
    null,
    "#eeeeee",
    "#cccccc",
    "#aaaaaa",
    "#999999",
    "#777777",
    "#555555",
  ],
}

export default {
  breakpoints: ["320px", "720px", "1028px", "1240px"],

  space: {
    "0": 0,
    "3xs": "0.1675rem",
    "2xs": "0.25rem",
    xs: "0.375rem",
    sm: "0.5rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
    "2xl": "3rem",
    "4xl": "4rem",
  },
  sizes: {
    "0": 0,
    "3xs": "0.25rem",
    "2xs": "0.5rem",
    xs: "0.75rem",
    sm: "1rem",
    md: "2rem",
    lg: "4rem",
    xl: "8rem",
    "2xl": "16rem",
    "4xl": "32rem",
  },
  fonts: {
    body:
      'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: {
    xs: 10,
    sm: 12,
    base: 16,
    md: 18,
    lg: 20,
    xl: 24,
    h1: 48,
    h2: 36,
    h3: 32,
  },
  fontWeights: {
    extraLight: 100,
    thin: 200,
    light: 300,
    regular: 400,
    semibold: 500,
    bold: 600,
    extrabold: 700,
    black: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    ...colorScales,
    background: "#241833",
    lightBackground: "#312244",
    white: "#FFFFFF",
    pink: "#FF00E7",
    teal: "#067E86",
    purple: "#701380",
    blue: "#066786",
  },
  shadows: {
    none: `0px solid #FFFFFF00`,
    base: `0px 4px 4px rgba(0,0,0,0.25)`,
    text: `1px 1px 4px rgba(0, 0, 0, 0.25)`,
    // neon: "0px 0px 3px #FF00E7",
    neon: "0px 0px 7px #FF00E7,0px 0px 7px #FF00E7",
    neonFocus: "0px 0px 20px #FF00E7,0px 0px 20px #FF00E7",
  },
  radii: {
    none: "0px",
    sm: "2px",
    md: "4px",
    lg: "8px",
    xl: "16px",
    full: "9999px",
  },
  borders: {
    text: "1.25px solid #000000",
  },
  borderWidths: {
    default: "1px",
    thick: "2px",
    thin: "0.5px",
  },
}
